import React, { useState, useEffect } from "react";
import { Warning as WarningSVG } from './../assets';
import { styled } from "linaria/react";

const WarningWrap = styled.div`
    width:100%;
    border-radius: 2px;
    background-color: #fff7e0; 
    box-shadow: 0 1px 2px 0 #eee5cb; 
    padding: 20px 15px; 
    display: flex;
`;

const WarningMessage = styled.div`
    font:500 13px/20px OpenSans, sans-serif;
    color: #434C5E; 
    margin-left: 12px;
`

const WarningLink = styled.a`
    margin: 0 5px;
    color: #478df0;
    word-wrap: break-word;
`
const WarningIconWrapper = styled.span`
    top: 14px;
    left: 15px;
    width: 20px;
    height: 20px;
`;

export default function Dashboard() {

    const [warning, setWarning] = useState({});
    useEffect(() => {
        setWarning(window.__cfWarning ? window.__cfWarning : {});
        // setWarning({
        //     message: 'We are facing an issue with the email service on some of our Shared Linux hosting servers. Our engineers are actively working on it to restore the services. Please follow our forum post for the latest updates.',
        //     link: 'https://www.google.com',
        //     linkText: "Click here for more info"
        // });
    }, []);

    return (
        <div>
            <p>Dashboard</p>
            <div id="dashboard-aaa">
                <p>aaa</p>
            </div>
            <>
                {warning?.message &&
                    <WarningWrap>
                        <WarningIconWrapper>
                            <img src={WarningSVG} />
                        </WarningIconWrapper>
                        <WarningMessage>
                            {warning.message}
                            {warning.link && warning.linkText &&
                                <WarningLink href={warning.link} target="_blank">
                                    {warning.linkText}
                                </WarningLink>
                            }
                        </WarningMessage>
                    </WarningWrap>
                }
            </>
            <div id="dashboard-bbb">
                <p>bbb</p>
            </div>
            <div id="dashboard-ccc" />
        </div>
    )
};
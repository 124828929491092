import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/header';
import Home from "./components/home";
import Dashboard from "./components/dashboard";
import About from "./components/about";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='about' element={<About />} />
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
